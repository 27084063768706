.api-section {
  position: relative;

  pre {
    white-space: pre-wrap;
  }

  table.api-table {
    min-width: 680px;

    thead th {
      color: white;
      font-size: 16px;
      background-color: $pink;
      border-radius: 4px 4px 0 0;
      text-transform: none;
      padding: 8px 24px;
    }

    tbody {
      pre {
        white-space: normal;
        margin: 4px;
        padding: 4px 16px;
      }

      td,
      th {
        padding: 0;
      }

      th {
        max-width: 150px;
      }
    }
  }
}

.api-body {
  max-width: 1200px;

  table {
    th {
      text-transform: none;
      font-size: 16px;
      font-weight: bold;
    }

    tr {
      border-bottom: 1px solid $lightgray;
    }

    td {
      vertical-align: middle;
    }

    hr {
      margin: 16px 0;
    }

    tr:last-child {
      border-bottom: none;
    }

    &.item-table {
      td {
        padding: 32px;
      }
    }

    &.list-table {
      td {
        padding: 16px 24px;
      }
    }
  }

  /* used to target the short description */
  > p:nth-child(2) {
    border-left: 5px solid $pink;
    font-size: 1rem;
    line-height: 1.25;
    padding-left: 0.5rem;
  }

  .export-list {
    a {
      &.deprecated {
        text-decoration: line-through;
      }
    }
  }
}
