.l-sub-section {
    color: $darkgray;
    background-color: rgba($pink, 0.05);
    border-left: 8px solid $pink;
    padding: 16px;
    margin-bottom: 8px;
    display: table;
    clear: both;
    width: 100%;
    box-sizing: border-box;

    h3 {
        margin: 8px 0 0;
    }

    a:hover {
        color: $pink;
        text-decoration: underline;
    }
}