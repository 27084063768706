.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 0;
}

.docs-card {
  @include card(194px, 30%);
  max-width: 340px;
  min-width: 262px;
  margin: 24px 8px;
  padding-bottom: 48px;
  position: relative;

  &:hover {
    text-decoration: none;

    section {
      color: $pink;
    }

    p {
      color: $darkgray;
      padding: 0 16px;
    }

    .card-footer {
      line-height: 32px;
      padding: 8px 16px;
      background-color: rgba($pink, 0.1);
      color: $pink;
    }
  }


  section {
    color: $darkgray;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    padding: 32px 0 24px;
    text-transform: none;
    text-align: center;
  }

  p {
    color: $darkgray;
    font-size: 13px;
    line-height: 24px;
    padding: 0 16px;
    margin: 0;
    text-align: center;
  }

  .card-footer {
    bottom: 0;
    border-top: 0.5px solid $lightgray;
    box-sizing: border-box;
    line-height: 48px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: right;
    color: $mediumgray;

    a {
      color: $mediumgray;
      font-size: 13px;
    }
  }
  .card-footer.center {
    text-align: center;
  }
}
