// TYPOGRAPHY
$main-font: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
$code-font: "Droid Sans Mono", monospace;

// Z-LAYER
$layer-1: 1;
$layer-2: 2;
$layer-3: 3;
$layer-4: 4;
$layer-5: 5;

// COLOR PALETTE
$pink: #d81b60;
$accentgrey: #62757f;
$brightred: #DD0031;
$darkred: #C3002F;
$white: #FFFFFF;
$offwhite: #FAFAFA;
$backgroundgray: #F1F1F1;
$lightgray: #DBDBDB;
$mist: #ECEFF1;
$mediumgray: #6e6e6e;
$darkgray: #333;
$black: #0A1014;
$orange: #FF9800;
$anti-pattern: $brightred;

// API & CODE COLORS
$amber-700: #FFA000;
$blue-400: #42A5F5;
$blue-500: #2196F3;
$blue-600: #1E88E5;
$blue-800: #1565C0;
$blue-900: #0D47A1;
$blue-grey-50: #ECEFF1;
$blue-grey-100: #CFD8DC;
$blue-grey-200: #B0BEC5;
$blue-grey-300: #90A4AE;
$blue-grey-400: #78909C;
$blue-grey-500: #607D8B;
$blue-grey-600: #546E7A;
$blue-grey-700: #455A64;
$blue-grey-800: #37474F;
$blue-grey-900: #263238;
$codegreen: #17ff0b;
$green-500: #4CAF50;
$green-800: #2E7D32;
$light-green-600: #7CB342;
$pink-600: #D81B60;
$purple-600: #8E24AA;
$teal-500: #009688;
$lightgrey: #F5F6F7;

// GRADIENTS
$bluegradient: linear-gradient(145deg,#0D47A1,#42A5F5);
$redgradient: linear-gradient(145deg,$darkred,$brightred);

// API LABEL COLOR AND SYMBOLS MAP
$api-symbols: (
  all: (
    content: ' ',
    background: $white
  ),
  decorator: (
    content: '@',
    background: $blue-800
  ),
  directive: (
    content: 'D',
    background: $pink-600
  ),
  pipe: (
    content: 'P',
    background: $blue-grey-600
  ),
  class: (
    content: 'C',
    background: $blue-500
  ),
  interface: (
    content: 'I',
    background: $teal-500
  ),
  function: (
    content: 'F',
    background: $green-800
  ),
  enum: (
    content: 'E',
    background: $amber-700
  ),
  const: (
    content: 'K',
    background: $mediumgray
  ),
  let: (
    content: 'K',
    background: $mediumgray
  ),
  var: (
    content: 'K',
    background: $mediumgray
  ),
  type-alias: (
    content: 'T',
    background: $light-green-600
  ),
  module: (
    content: 'Pk',
    background: $purple-600
  )
);

// OTHER
$small-breakpoint-width: 840px;
$phone-breakpoint: 480px;
$tablet-breakpoint: 800px;