/* API EDIT ICON */
#api {
  .api-filter .material-icons {
    right: 48px;
  }
}

/* API LIST STYLES */

aio-api-list {
    div.form-search i.material-icons {
        width: 20px;
        pointer-events: none;
    }

    .form-search input {
        width: 182px;
    }

    .api-list-container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      h2 {
        margin-top: 16px;
      }
    }
}

.api-filter {
  display: flex;
  margin: 0 auto;

  @media (max-width: 600px) {
      flex-direction: column;
      margin: 16px auto;
  }

  .form-select-menu, .form-search {
      margin: 8px;
  }
}

/* LAYOUT */

.docs-content {
  position: relative;
}

.l-content-small {
  padding: 16px;
  max-width: 1100px;
  margin: 0;

  @media handheld and (max-width: $phone-breakpoint),
  screen and (max-device-width: $phone-breakpoint),
  screen and (max-width: $tablet-breakpoint) {
    padding: 24px 0 0;
  }
}

/* SEARCH BAR */

.form-search {
  position: relative;

  input {
    box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
    box-sizing: border-box;
    border: 1px solid $white;
    color: $blue-600;
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    outline: none;
    padding: 0 16px 0 32px;
    transition: all .2s;

    // PLACEHOLDER TEXT
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $blue-grey-100;
      font-size: 14px;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $blue-grey-100;
      font-size: 14px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $blue-grey-100;
      font-size: 14px;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $blue-grey-100;
      font-size: 14px;
    }

    &:focus {
      border: 1px solid $blue-400;
      box-shadow: 0 2px 2px rgba($blue-400, 0.24), 0 0 2px rgba($blue-400, 0.12);
    }
  }

  .material-icons {
    color: $blue-grey-100;
    font-size: 20px;
    left: 8px;
    position: absolute;
    top: 6px;
    z-index: $layer-1;
  }
}

/* API SYMBOLS */

/* SYMBOL CLASS */

.symbol {
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba($black, .24);
  color: $white;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  width: 16px;

  // SYMBOL TYPES
  // Symbol mapping variables in *constants*
  @each $name, $symbol in $api-symbols {
    &.#{$name} {
      background: map-get($symbol, background);

      &:before {
        content: map-get($symbol, content);
      }
    }
  }
}

/* API HOMEE PAGE */

/* API FILTER MENU */

.api-filter {
  aio-select {
    width: 200px;

    .symbol {
      margin-right: 8px;
    }
  }

  .form-search {
    float: left;
  }
}

/* API CLASS LIST */

.docs-content .api-list {
  list-style: none;
  margin: 0 0 32px -8px;
  padding: 0;
  overflow: hidden;

  @media screen and (max-width: 600px) {
        margin: 0 0 0 -8px;
    }

  li {
    font-size: 14px;
    margin: 8px 0;
    line-height: 14px;
    padding: 0;
    float: left;
    width: 33%;
    overflow: hidden;
    min-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .symbol {
      margin-right: 8px;
    }
    a {
      color: $blue-grey-600;
      display: inline-block;
      line-height: 16px;
      padding: 0 16px 0;
      text-decoration: none;
      transition: all .3s;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background: $blue-grey-50;
        color: $blue-500;
      }
    }

    .stability {
      &.deprecated {
        text-decoration: line-through;
      }

      &.experimental {
        font-style: italic;
      }
    }
  }
}

.docs-content .h2-api-docs,
.docs-content .h2-api-docs:first-of-type {
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
}

.code-links {
  a {
    code, .api-doc-code {
      color: #1E88E5 !important;
    }
  }
}

.openParens {
  margin-top: 15px;
}

.endParens {
  margin-bottom: 20px !important;
}

p {

  &.selector {
    margin: 0;
  }

  &.location-badge {
    margin: 0 0 16px 16px !important;
  }

  .api-doc-code {
    border-bottom: 0;

    :hover {
      border-bottom: none;
    }
  }
}

.row-margin {
  margin-bottom: 36px;
  h2 {
    line-height: 28px;
  }
}

.code-margin {
  margin-bottom: 8px;
}

.no-bg {
  background: none;
  padding: 0;
}

.no-bg-with-indent {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  margin-top: 6px;
  margin-bottom: 0;
  background: none;
}

.code-background {
  padding: 0 5px 0;

  span.pln {
    color: #1E88E5 !important;
  }
}

.code-anchor {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.api-doc-code {
  font-size: 14px;
  color: #1a2326;

  // the last .pln (white space) creates additional spacing between sections of the api doc. Remove it.
  &.no-pln {
    .pln:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .docs-content {
    // Overrides display flex from angular material.
    // This was added because Safari doesn't play nice with layout="column".
    // Look of API doc in Chrome and Firefox remains the same, and is fixed for Safari.
    .layout-xs-column {
      display: block !important;
    }
  }

  .api-doc-code {
    font-size: 12px;
  }

  p.location-badge {
    position: relative;
    font-size: 11px;
  }
}
